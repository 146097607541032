import { Document } from '../../document/entities/document';
import { SignStreamInput } from '../dtos/sign-stream.input';
import { SignStreamOutput } from '../dtos/sign-stream.output';
import { ErrorTranslator } from '../../base/error-translator';
import { SignerInterface } from '../interfaces/signer.interface';
import { SignStreamStrategy } from '../enums/sign-stream-strategy';
import { FileConvertionsUtils } from '../../utils/file-convertions.utils';
import { SignStreamServiceInterface } from '../interfaces/sign-stream.service.interface';
import { SignatureRepositoryInterface } from '../interfaces/repositories/signature.repository.interface';

export class BrowserSignStreamService implements SignStreamServiceInterface {
  readonly strategy = SignStreamStrategy.OnBrowser;

  constructor(
    public readonly document: Document,
    private readonly _signer: SignerInterface,
    private readonly _signatureRepository: SignatureRepositoryInterface,
  ) { }

  async handle(input: SignStreamInput): Promise<SignStreamOutput> {
    const documentCode = input.document.code;
    const userAlreadySigned = await this._signatureRepository.userAlreadySigned(documentCode);
    if (userAlreadySigned) {
      const errorOutput = ErrorTranslator.translateFromError('Document already signed');
      return new SignStreamOutput({ ...errorOutput, documentCode });
    }
    
    try {
      const documentBuffer = input.document.buffer;
      const documentBase64 = FileConvertionsUtils.arrayBufferToBase64(documentBuffer!);
      const signOnBrowserOutput = await this._signer.signStreamOnBrowser({
        documentBase64,
        documentRotation: input.document.rotation,
        certificateThumb: input.certificateThumbprint,
        stampAdditionalInfos: input.stampAdditionalInfos,
      });

      const signedDocumentUrl = `data:application/pdf;base64,${signOnBrowserOutput.signedDocumentContent}`;
      return new SignStreamOutput({ signedDocumentUrl, documentCode });
    } 
    catch (error) {
      const errorOutput = ErrorTranslator.translateFromError(error);
      return new SignStreamOutput({ ...errorOutput, documentCode });
    }
  }
}
