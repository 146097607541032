import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';

import { authInterceptor } from './interceptors/auth.interceptor';

import { GetSignatureAdapterInstruction } from '../builder/instructions/adapters/get-signature.adapter.instruction';
import { AuthenticationRepositoryInstruction } from '../builder/instructions/repositories/authentication.repository.instruction';
import { DocumentRepositoryInstruction } from '../builder/instructions/repositories/document.repository.instruction';
import { SignatureRepositoryInstruction } from '../builder/instructions/repositories/signature.repository.instruction';
import { CreateSignatureServiceInstruction } from '../builder/instructions/services/create-signature.service.instruction';
import { GetAuthenticationServiceInstruction } from '../builder/instructions/services/get-authentication.service.instruction';
import { GetCurrentUserServiceInstruction } from '../builder/instructions/services/get-current-user.service.instruction';
import { GetDocumentsSignaturesServiceInstruction } from '../builder/instructions/services/get-documents-signatures.service.instruction';
import { GetRemoteDocumentServiceInstruction } from '../builder/instructions/services/get-remote-document.service.instruction';
import { SetAuthenticationServiceInstruction } from '../builder/instructions/services/set-authentication.service.instruction';
import { SignAndUploadDocumentServiceInstruction } from '../builder/instructions/services/sign-and-upload-document.service.instruction';
import { SignStreamServiceFactoryInstruction } from '../builder/instructions/services/sign-stream.service.factory.instruction';
import { UploadDocumentServiceInstruction } from '../builder/instructions/services/upload-document.service.instruction';
import { SettingsInstruction } from '../builder/instructions/settings/settings.instruction';
import { CertificateInstruction } from '../builder/instructions/signatures/certificate.instruction';
import { SignerInstruction } from '../builder/instructions/signatures/signer.instruction';
import { WebPkiConnectionInstruction } from '../builder/instructions/signatures/web-pki.connection.instruction';
import { DocumentStorageSignQueueServiceInstruction } from '../builder/instructions/services/document-storage-sign-queue.service.instruction';
import { UtilsHandle } from './shared/utils/redirect.handle';
import { GetInternalCertificateServiceInstruction } from '../builder/instructions/services/get-certificate.service.instruction';
import { InternalCertificateRepositoryInstruction } from '../builder/instructions/repositories/internal-certificate.repository.instruction';
import { CreateInternalCertificateServiceInstruction } from '../builder/instructions/services/create-certificate.service.instruction';
import { AuthenticationCallbackRepositoryInstruction } from '../builder/instructions/repositories/authentication-callback.repository.instruction';
import { VerifyPasswordServiceInstruction } from '../builder/instructions/services/verify-password.service.instruction';
import { ActorApiServiceInstruction } from '../builder/instructions/services/actor-api.repository.service.instruction';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    new SettingsInstruction(),
    new AuthenticationRepositoryInstruction(),
    new AuthenticationCallbackRepositoryInstruction(),
    new WebPkiConnectionInstruction(),
    new SignerInstruction(),
    new CertificateInstruction(),
    new SignStreamServiceFactoryInstruction(),
    new DocumentRepositoryInstruction(),
    new SignatureRepositoryInstruction(),
    new InternalCertificateRepositoryInstruction(),
    new GetCurrentUserServiceInstruction(),
    new UploadDocumentServiceInstruction(),
    new GetRemoteDocumentServiceInstruction(),
    new SetAuthenticationServiceInstruction(),
    new GetAuthenticationServiceInstruction(),
    new CreateSignatureServiceInstruction(),
    new GetSignatureAdapterInstruction(),
    new SignAndUploadDocumentServiceInstruction(),
    new GetDocumentsSignaturesServiceInstruction(),
    new DocumentStorageSignQueueServiceInstruction(),
    new GetInternalCertificateServiceInstruction(),
    new CreateInternalCertificateServiceInstruction(),
    new VerifyPasswordServiceInstruction(),
    new ActorApiServiceInstruction(),
    UtilsHandle,
    provideHttpClient(withInterceptors([authInterceptor])),
    provideRouter(routes),
  ],
};
