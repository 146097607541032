export class FormDataUtils {
  static appendObject(formData: FormData, prefix: string, object: any): void {
    if (!object) {
      formData.append(prefix, "");
      return;
    }

    Object.keys(object).forEach(key => {
      formData.append(`${prefix}.${key}`, object[key]);
    });
  }
}