import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { SignStreamServiceFactory } from '../../../domain/signatures/factories/sign-stream.service.factory';

export class SignStreamServiceFactoryInstruction implements ClassProvider {
  provide = Types.SignStreamServiceFactory;
  deps = [Types.Settings, Types.Signer, Types.Certificates];
  useClass = SignStreamServiceFactory;
}
