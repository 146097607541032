import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { GetCertificateService } from '../../../domain/signatures/services/get-certificate.service';

export class GetInternalCertificateServiceInstruction implements ClassProvider {
  provide = Types.GetCertificateService;
  deps = [Types.InternalCertificateRepository];
  useClass = GetCertificateService;
}
