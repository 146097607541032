import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { lastValueFrom } from 'rxjs';
import { Signature } from '../../../domain/signatures/entities/signature';
import { GetSignatureAdapterInterface } from '../../../domain/signatures/interfaces/adapters/get-signature.adapter.interface';
import { Types } from '../../../domain/types.domain';

@Injectable()
export class GetSignatureAdapter implements GetSignatureAdapterInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async handle(userId: string, documentCode: string): Promise<Signature> {
    const requestUrl = `${this._settings.signManagerUrl}/signature/v1/${userId}/${documentCode}`;
    return lastValueFrom(this._http.get<Signature>(requestUrl));
  }
}
