import { ClassProvider } from '@angular/core';

import { CreateSignatureService } from '../../../domain/signatures/services/create-signature.service';
import { Types } from '../../../domain/types.domain';

export class CreateSignatureServiceInstruction implements ClassProvider {
  provide = Types.CreateSignature;
  deps = [Types.DocumentRepository];
  useClass = CreateSignatureService;
}
