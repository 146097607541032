import { Injectable, inject } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

import { GetCurrentUserServiceInterface } from '../interfaces/services/get-current-user.service.interface';
import { User } from '../entities/user';
import { Types } from '../../types.domain';
import { GetAuthenticationServiceInterface } from '../../authentication/interfaces/services/get-authentication.service.interface';

@Injectable()
export class GetCurrentUserService implements GetCurrentUserServiceInterface {
  private readonly _authenticationService = inject<GetAuthenticationServiceInterface>(Types.GetAuthenticationService);

  handle(): Promise<User> {
    const authentication = this._authenticationService.handle();
    const user = jwtDecode<User>(authentication.token);
    return Promise.resolve(user);
  }
}
