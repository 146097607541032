import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Types } from '../../../domain/types.domain';
import { jwtDecode } from 'jwt-decode';

export const authGuard: CanActivateFn = (route, state) => {
  const queryParamToken = route.queryParams['token'];

  if (queryParamToken) {
    return true;
  }

  const getAuthService = inject(Types.GetAuthenticationService);
  const settings = inject(Types.Settings);
  const token = getAuthService.handle()?.token;
  if (token) {
    const tokenDecoded = jwtDecode<any>(token);
    var current_time = new Date().getTime() / 1000;

    if (current_time < tokenDecoded.exp) {
      return true;
    }
  }

  const currentUrl = state.url;

  const loginCallbackUrl = `${window.location.origin}/login-callback`;
  const redirectUrl = `${settings.loginUrl}modeload?redirectTo=${loginCallbackUrl}&redirect=${currentUrl}`;
  window.open(redirectUrl, '_self');

  return false;
};
