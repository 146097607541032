import { Output } from "../../base/outputs";
import { QueueItemStatus } from "../../signatures/enums/queue-item-status";

export class QueueItemOutput<K extends Output> {
  data?: K;
  identifier!: string;
  status!: QueueItemStatus;

  constructor(data: Partial<QueueItemOutput<K>>) {
    Object.assign(this, data);
  }
}