import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { GetRemoteDocumentService } from '../../../domain/document/services/get-remote-document.service';

export class GetRemoteDocumentServiceInstruction implements ClassProvider {
  provide = Types.GetRemoteDocumentService;
  deps = [Types.Settings, Types.DocumentRepository];
  useClass = GetRemoteDocumentService;
}
