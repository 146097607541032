import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { DocumentStorageSignQueueService } from '../../../domain/signatures/services/document-storage-sign-queue.service';

export class DocumentStorageSignQueueServiceInstruction implements ClassProvider {
  provide = Types.DocumentStorageSignQueueService;
  deps = [Types.SignAndUploadDocumentService];
  useClass = DocumentStorageSignQueueService;
}
