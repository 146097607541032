import { ClassProvider } from '@angular/core';

import { GetAuthenticationService } from '../../../domain/authentication/services/get-authentication.service';
import { Types } from '../../../domain/types.domain';

export class GetAuthenticationServiceInstruction implements ClassProvider {
  provide = Types.GetAuthenticationService;
  deps = [Types.AuthenticationRepository];
  useClass = GetAuthenticationService;
}
