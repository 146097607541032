import { Types } from '../../types.domain';
import { Output } from '../../base/outputs';
import { Observable, Subscriber } from 'rxjs';
import { ErrorEnum } from '../../base/error.enum';
import { Injectable, inject } from '@angular/core';
import { SignAndUploadDocumentInput } from '../../document/dtos/sign-and-upload-document.input';
import { SignAndUploadDocumentOutput } from '../../document/dtos/sign-and-upload-document.output';
import { UploadDocumentServiceInterface } from '../../document/interfaces/services/upload-document.service.interface';
import { SignAndUploadDocumentServiceInterface } from '../../document/interfaces/services/sign-and-upload-document.service.interface';

@Injectable()
export class SignAndUploadDocumentService implements SignAndUploadDocumentServiceInterface {
  private readonly _uploadDocumentService = inject<UploadDocumentServiceInterface>(Types.UploadDocumentService);

  handle(input: SignAndUploadDocumentInput): Observable<SignAndUploadDocumentOutput> {
    return new Observable<any>((subscriber: Subscriber<SignAndUploadDocumentOutput>) => {
      this.asyncHandle(input, subscriber);
    });
  }

  async asyncHandle(
    input: SignAndUploadDocumentInput,
    subscriber: Subscriber<SignAndUploadDocumentOutput>
  ): Promise<void> {
    try {
      const signOutput = await input.signStreamService.handle(input);
      subscriber.next(signOutput);

      if (signOutput?.hasErrors) {
        subscriber.complete();
        return;
      }

      const uploadDocumentInput = { document: input.document, signedDocumentUrl: signOutput.signedDocumentUrl! };
      const uploadDocumentOutput = await this._uploadDocumentService.handle(uploadDocumentInput);
      subscriber.next(uploadDocumentOutput);
    } catch (error) {
      subscriber.next(new Output([ErrorEnum.GENERIC]));
    }

    subscriber.complete();
  }
}
