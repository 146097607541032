import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import LacunaWebPKI from 'web-pki';

import { Types } from '../../../domain/types.domain';
import { Output } from '../../../domain/base/outputs';
import { WebPkiConnection } from './web-pki.connection';
import { FormDataUtils } from '../../../domain/utils/form-data.utils';
import { SignatureVisualRepresentation } from './signature-visual-representation';
import { StartRemoteSignWebPkiOutput } from './dtos/start-remote-sign.web-pki.output';
import { SignerInterface } from '../../../domain/signatures/interfaces/signer.interface';
import { BrowserSignStreamInput } from '../../../domain/signatures/dtos/browser.sign-stream.input';
import { BrowserSignStreamOutput } from '../../../domain/signatures/dtos/browser.sign-stream.output';
import { DocumentStorageSignInput } from '../../../domain/signatures/dtos/document-storage.sign.input';
import { RemoteServerSignStreamInput } from '../../../domain/signatures/dtos/remote-server.sign-stream.intput';
import { RemoteServerSignStreamOutput } from '../../../domain/signatures/dtos/remote-server.sign-stream.output';
import { ErrorTranslator } from '../../../domain/base/error-translator';
import { DocumentStorageInternalSignInput } from '../../../domain/signatures/dtos/document-storage-internal-sign.input';

@Injectable()
export class SignerWebPki implements SignerInterface {
  readonly _http = inject(HttpClient);
  readonly _connection = inject(WebPkiConnection);
  private readonly _settings = inject(Types.Settings);

  signStreamOnBrowser(input: BrowserSignStreamInput): Promise<BrowserSignStreamOutput> {
    const visualRepresentation = new SignatureVisualRepresentation(input.documentRotation, input.stampAdditionalInfos);

    return new Promise((resolve, reject) => {
      this._connection
        .signPdf({
          content: input.documentBase64,
          policy: LacunaWebPKI.PadesPolicies.Basic,
          certificateThumbprint: input.certificateThumb,
          output: { mode: LacunaWebPKI.OutputModes.ReturnContent },
          trustArbitrators: [this._connection.standardTrustArbitrators.pkiBrazil],
          visualRepresentation: visualRepresentation.getDefault(),
        })
        .fail((error) => reject(error))
        .success((result) => {
          const signedDocumentContent = result.signatureInfo.content;
          resolve(new BrowserSignStreamOutput({ signedDocumentContent }));
        });
    });
  }

  async signStreamOnRemoteServer(input: RemoteServerSignStreamInput): Promise<RemoteServerSignStreamOutput> {
    const startSignBody = new FormData();
    startSignBody.append('document', input.document);
    startSignBody.append('certificateBase64', input.certificateBase64);
    FormDataUtils.appendObject(startSignBody, 'stampAdditionalInfos', input.stampAdditionalInfos);

    const startSignUrl = `${this._settings.remoteSignerUrl.v1}/remote/sign/start`;
    const startRemoteSignOutput = await lastValueFrom(
      this._http.post<StartRemoteSignWebPkiOutput>(startSignUrl, startSignBody)
    );

    const signature = await this.signHash(
      input.certificateThumbprint,
      startRemoteSignOutput.hashToSign,
      startRemoteSignOutput.digestAlgorithm
    );

    const filesWorkspaceIdentifier = startRemoteSignOutput.filesWorkspaceIdentifier;
    const finishSigntUrl = `${this._settings.remoteSignerUrl.v1}/remote/sign/finish`;
    const finishSignBody = { signature, filesWorkspaceIdentifier };
    await lastValueFrom(this._http.post(finishSigntUrl, finishSignBody));

    const downloadDocumentUrl = `${this._settings.remoteSignerUrl.v1}/download/signed-document`;
    const signedDocumentUrl = `${downloadDocumentUrl}/${filesWorkspaceIdentifier}`;
    return new RemoteServerSignStreamOutput({ signedDocumentUrl });
  }

  async signUsingDocumentStorage(input: DocumentStorageSignInput): Promise<Output> {
    try {
      const startSignUrl = `${this._settings.remoteSignerUrl.v1}/document-storage/sign/start`;
      const startRemoteSignOutput = await lastValueFrom(
        this._http.post<StartRemoteSignWebPkiOutput>(startSignUrl, input)
      );

      const signature = await this.signHash(
        input.certificateThumbprint,
        startRemoteSignOutput.hashToSign,
        startRemoteSignOutput.digestAlgorithm
      );

      const filesWorkspaceIdentifier = startRemoteSignOutput.filesWorkspaceIdentifier;
      const finishSigntUrl = `${this._settings.remoteSignerUrl.v1}/document-storage/sign/finish`;
      const finishSignBody = { signature, filesWorkspaceIdentifier, documentCode: input.documentCode };
      await lastValueFrom(this._http.post(finishSigntUrl, finishSignBody));
      return new Output();
    }
    catch (error: unknown) {
      return ErrorTranslator.translateFromError(error);
    }
  }

  async signInternalUsingDocumentStorage(input: DocumentStorageInternalSignInput): Promise<Output> {
    try {
      const startSignUrl = `${this._settings.remoteSignerUrl.v1}/document-storage/internal/sign`;
      await lastValueFrom(
        this._http.post<StartRemoteSignWebPkiOutput>(startSignUrl, input)
      );

      return new Output();
    }
    catch (error: unknown) {
      return ErrorTranslator.translateFromError(error);
    }
  }

  private signHash(thumbprint: string, hash: string, digestAlgorithm: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._connection
        .signHash({ thumbprint, hash, digestAlgorithm })
        .fail((error) => reject(error))
        .success((result) => resolve(result));
    });
  }
}
