import { Output } from "../../base/outputs";

export class SignStreamOutput extends Output {
  public readonly documentCode!: string;
  public readonly signedDocumentUrl?: string;

  constructor(data: Partial<SignStreamOutput>) {
    super();
    Object.assign(this, data);
  }
}