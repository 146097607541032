import { Injectable, inject } from "@angular/core";

import { WebPkiConnection } from "./web-pki.connection";
import { GetCertificateOutput } from "../../../domain/signatures/dtos/get-certificate.output";
import { CertificatesInterface } from "../../../domain/signatures/interfaces/certificates.interface";

@Injectable()
export class CertificatesWebPki implements CertificatesInterface {

  readonly _connection = inject(WebPkiConnection);

  async getAllCertificates(): Promise<GetCertificateOutput[]> {
    return new Promise((resolve, reject) => {
      this._connection.listCertificates()
        .fail(error => reject(error))
        .success((certificates) => {
          const output = certificates.map(c => 
            new GetCertificateOutput({
              subject: c.subjectName,
              issuerName: c.issuerName,
              thumbprint: c.thumbprint,
            })
          );

          resolve(output);
        })
    });
  }
  
  getICPRootCertificates(): Promise<GetCertificateOutput[]> {
    throw new Error("Method not implemented.");
  }

  getCertificateBase64(certificateThumbprint: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._connection.readCertificate({ thumbprint: certificateThumbprint })
        .fail((error) => reject(error))
        .success((result) => resolve(result));
    });
  }
}