import { FactoryProvider } from '@angular/core';

import Settings from '../settings/settings';
import { Types } from '../../../domain/types.domain';
import { WebPkiConnection } from '../../../infrastructure/signatures/web-pki/web-pki.connection';

export class WebPkiConnectionInstruction implements FactoryProvider {
  provide = WebPkiConnection;
  deps = [Types.Settings];
  useFactory = (settings: Settings): WebPkiConnection => {
    const signer = new WebPkiConnection(settings.webpki.apiKey);
    signer.init({
      ready: () => signer.initialized = true,
      notInstalled: (status) => signer.errorStatus = status
    });

    return signer;
  }
}
