import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';
import { AuthenticationCallbackRepositoryInterface } from '../interfaces/repositories/authentication-callback.repository.interface';
import { VerifyPasswordInput } from '../dtos/verify-password.input';
import { VerifyPasswordOutput } from '../dtos/verify-password.output';
import { VerifyPasswordServiceInterface } from '../interfaces/services/verify-password.service.interface';

@Injectable()
export class VerifyPasswordService implements VerifyPasswordServiceInterface {
  private readonly _authenticationRepository = inject<AuthenticationCallbackRepositoryInterface>(
    Types.AuthenticationCallbackRepository
  );

  async handle(input: VerifyPasswordInput): Promise<VerifyPasswordOutput> {
    return await this._authenticationRepository.verifyPassword(input);
  }
}
