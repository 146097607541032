import { Injectable, inject } from '@angular/core';
import { Types } from '../../types.domain';
import { GetDocumentsSignaturesInput } from '../dtos/get-documents-signatures.input';
import { GetDocumentsSignaturesOutput } from '../dtos/get-documents-signatures.output';
import { GetDocumentsSignaturesServiceInterface } from '../interfaces/get-documents-signatures.service.interface';
import { SignatureRepositoryInterface } from '../interfaces/repositories/signature.repository.interface';

@Injectable()
export class GetDocumentsSignaturesService implements GetDocumentsSignaturesServiceInterface {
  private readonly _signatureRepository = inject<SignatureRepositoryInterface>(Types.SignatureRepository);

  async handle(input: GetDocumentsSignaturesInput): Promise<GetDocumentsSignaturesOutput[]> {
    return this._signatureRepository.getByMultipleDocument(input.documentCode);
  }
}
