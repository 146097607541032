import { ClassProvider } from '@angular/core';

import { SetAuthenticationService } from '../../../domain/authentication/services/set-authentication.service';
import { Types } from '../../../domain/types.domain';

export class SetAuthenticationServiceInstruction implements ClassProvider {
  provide = Types.SetAuthenticationService;
  deps = [Types.AuthenticationRepository];
  useClass = SetAuthenticationService;
}
