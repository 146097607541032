import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { Document } from '../../../domain/document/entities/document';
import { BranchUpload } from '../../../domain/document/entities/branch-upload';
import { DocumentRepositoryInterface } from '../../../domain/document/interfaces/repositories/document.repository.interface';

@Injectable()
export class DocumentHttpRepository implements DocumentRepositoryInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async getDocumentProperties(
    documentCode: string, 
    branchCode: string, 
    getOriginalBranchIfNotExists?: boolean
  ): Promise<Omit<Document, 'buffer'>> {
    const requestOptions = { params: {
      branchCode,
      ...(getOriginalBranchIfNotExists ? { alternativeBranchCode: 'original' } : { }),
    }};

    const requestUrl = `${this._settings.documentStorageUrl.v1}/document/${documentCode}/properties`;
    return lastValueFrom(this._http.get<Omit<Document, 'buffer'>>(requestUrl, requestOptions));
  }

  async prepareBranchUpload(document: Document, branchCode: string): Promise<BranchUpload> {
    const requestUrl = `${this._settings.documentStorageUrl.v1}/upload/prepare/branch/${branchCode}`;
    return lastValueFrom(this._http.post<BranchUpload>(requestUrl, { documentCode: document.code }));
  }
}
