import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { WebPkiConnection } from '../../../infrastructure/signatures/web-pki/web-pki.connection';
import { CertificatesWebPki } from '../../../infrastructure/signatures/web-pki/certificates.web-pki';

export class CertificateInstruction implements ClassProvider {
  provide = Types.Certificates;
  deps = [WebPkiConnection];
  useClass = CertificatesWebPki;
}
