import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { Types } from '../../types.domain';
import { GetRemoteDocumentInput } from '../dtos/get-remote-document.input';
import { GetRemoteDocumentOutput } from '../dtos/get-remote-document.output';
import { Document } from '../entities/document';
import { DocumentRepositoryInterface } from '../interfaces/repositories/document.repository.interface';
import { GetRemoteDocumentServiceInterface } from '../interfaces/services/get-remote-document.service.interface';

@Injectable()
export class GetRemoteDocumentService implements GetRemoteDocumentServiceInterface {
  private readonly _http = inject(HttpClient);
  private readonly _documentRepository = inject<DocumentRepositoryInterface>(Types.DocumentRepository);

  async handle(input: GetRemoteDocumentInput): Promise<GetRemoteDocumentOutput> {
    const documentInfos = await this._documentRepository.getDocumentProperties(
      input.documentCode,
      input.branchCode,
      true
    );

    const documentBuffer = await lastValueFrom(
      this._http.get(documentInfos.downloadUrl, {
        responseType: 'arraybuffer',
        headers: { excludeInterceptors: 'true' },
      })
    );
    return new Document({
      ...documentInfos,
      isLocalFile: false,
      buffer: documentBuffer,
      code: input.documentCode,
    });
  }
}
