import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { SignerWebPki } from '../../../infrastructure/signatures/web-pki/signer.web-pki';
import { WebPkiConnection } from '../../../infrastructure/signatures/web-pki/web-pki.connection';

export class SignerInstruction implements ClassProvider {
  provide = Types.Signer;
  deps = [WebPkiConnection, Types.GetSignatureAdapter, Types.GetCurrentUserService];
  useClass = SignerWebPki;
}
