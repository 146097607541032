import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { GetCurrentUserService } from '../../../domain/user/services/get-current-user.service';

export class GetCurrentUserServiceInstruction implements ClassProvider {
  provide = Types.GetCurrentUserService;
  deps = [Types.GetAuthenticationService];
  useClass = GetCurrentUserService;
}
