import { ClassProvider } from '@angular/core';

import { GetDocumentsSignaturesService } from '../../../domain/signatures/services/get-documents-signatures.service';
import { Types } from '../../../domain/types.domain';

export class GetDocumentsSignaturesServiceInstruction implements ClassProvider {
  provide = Types.GetDocumentsSignaturesService;
  deps = [Types.SignatureRepository];
  useClass = GetDocumentsSignaturesService;
}
