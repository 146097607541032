import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { Signature } from '../../../domain/signatures/entities/signature';
import { CreateSignatureInput } from '../../../domain/signatures/dtos/create-signature.input';
import { GetDocumentsSignaturesOutput } from '../../../domain/signatures/dtos/get-documents-signatures.output';
import { GetCurrentUserServiceInterface } from '../../../domain/user/interfaces/services/get-current-user.service.interface';
import { GetSignatureAdapterInterface } from '../../../domain/signatures/interfaces/adapters/get-signature.adapter.interface';
import { SignatureRepositoryInterface } from '../../../domain/signatures/interfaces/repositories/signature.repository.interface';
import { CheckMyExistingSignaturesOnDocumentsOutput } from '../../../domain/signatures/dtos/check-my-existing-signatures-on-documents.output';

@Injectable()
export class SignatureHttpRepository implements SignatureRepositoryInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);
  private readonly _signatureAdapter = inject<GetSignatureAdapterInterface>(Types.GetSignatureAdapter);
  private readonly _getCurrentUserService = inject<GetCurrentUserServiceInterface>(Types.GetCurrentUserService);

  async add(signature: CreateSignatureInput): Promise<Signature> {
    const requestUrl = `${this._settings.signManagerUrl}/signature/v1/`;
    return lastValueFrom(this._http.post<Signature>(requestUrl, signature));
  }

  async userAlreadySigned(documentCode: string): Promise<boolean> {
    const user = await this._getCurrentUserService.handle();
    const signature = await this._signatureAdapter.handle(user.sub, documentCode);
    return !!signature?.id;
  }

  async getByMultipleDocument(codes: string[]): Promise<GetDocumentsSignaturesOutput[]> {
    const requestUrl = `${this._settings.signManagerUrl}/signature/v1/multiple-document`;
    const documentCodes = codes.join(',');
    return lastValueFrom(this._http.get<GetDocumentsSignaturesOutput[]>(requestUrl, { params: { documentCodes } }));
  }

  async checkMyExistingSignaturesOnDocuments(codes: string[]): Promise<CheckMyExistingSignaturesOnDocumentsOutput[]> {
    const requestUrl = `${this._settings.signManagerUrl}/signature/v1/my/check-existing-signatures-on-documents`;
    const documentCodes = codes.join(',');
    return lastValueFrom(this._http.get<CheckMyExistingSignaturesOnDocumentsOutput[]>(requestUrl, { params: { documentCodes } }));
  }
}
