import { Injectable, inject } from '@angular/core';
import { CreateSignatureInput } from '../dtos/create-signature.input';
import { Signature } from '../entities/signature';
import { CreateSignatureServiceInterface } from '../interfaces/create-signature.service.interface';
import { SignatureRepositoryInterface } from '../interfaces/repositories/signature.repository.interface';
import { Types } from '../../types.domain';

@Injectable()
export class CreateSignatureService implements CreateSignatureServiceInterface {
  private readonly _signatureRepository = inject<SignatureRepositoryInterface>(Types.SignatureRepository);

  async handle(input: CreateSignatureInput): Promise<Signature> {
    return this._signatureRepository.add(input);
  }
}
