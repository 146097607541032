import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { CreateCertificateService } from '../../../domain/signatures/services/create-certificate.service';

export class CreateInternalCertificateServiceInstruction implements ClassProvider {
  provide = Types.CreateCertificateService;
  deps = [Types.InternalCertificateRepository];
  useClass = CreateCertificateService;
}
