import { Injectable } from '@angular/core';
import { AuthenticationRepositoryInterface } from '../../../domain/authentication/interfaces/repositories/authentication.repository.interface';
import { Authentication } from '../../../domain/authentication/entities/authentication';

@Injectable()
export class AuthenticationMemoryRepository implements AuthenticationRepositoryInterface {
  private _token!: Authentication;

  setToken(data: Authentication): Authentication {
    localStorage.setItem('signer-token', data.token);
    return (this._token = data);
  }
  getToken(): Authentication {
    if (!this._token) {
      const token = localStorage.getItem('signer-token');
      if (token) {
        this._token = { token };
      }
    }
    return this._token;
  }
}
