import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Types } from '../../../domain/types.domain';

import { InternalCertificateRepositoryInterface } from '../../../domain/signatures/interfaces/repositories/internal-certificate.repository.interface';
import { InternalCertificate } from '../../../domain/signatures/dtos/create-internal-certificate.output';

@Injectable()
export class InternalCertificateHttpRepository implements InternalCertificateRepositoryInterface {
    private readonly _http = inject(HttpClient);
    private readonly _settings = inject(Types.Settings);

    async create(): Promise<InternalCertificate> {
        const requestUrl = `${this._settings.certificatesUrl}/certificates`;
        return lastValueFrom(this._http.post<InternalCertificate>(requestUrl, {}));
    }

    async listByUser(): Promise<InternalCertificate> {
        const requestUrl = `${this._settings.certificatesUrl}/certificates/my`;
        return lastValueFrom(this._http.get<InternalCertificate>(requestUrl));
    }

}
