import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { SignatureHttpRepository } from '../../../infrastructure/repositories/http/signature.http.repository';

export class SignatureRepositoryInstruction implements ClassProvider {
  provide = Types.SignatureRepository;
  deps = [Types.Settings];
  useClass = SignatureHttpRepository;
}
