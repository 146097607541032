export class Document {
  readonly size!: number;
  readonly title?: string;
  readonly filename!: string;
  readonly code!: string;
  readonly rotation!: number;
  readonly buffer?: ArrayBuffer;
  readonly downloadUrl!: string;
  readonly isLocalFile: boolean = false;
  readonly alreadySignedByCurrentUser?: boolean;

  constructor(data: Partial<Document>) {
    Object.assign(this, data);
  }
}
