import { Injectable, inject } from '@angular/core';

import { AuthenticationTokenInput } from '../dtos/authentication.input';
import { Authentication } from '../entities/authentication';
import { AuthenticationRepositoryInterface } from '../interfaces/repositories/authentication.repository.interface';
import { SetAuthenticationServiceInterface } from '../interfaces/services/set-authentication.service.interface';

import { Types } from '../../types.domain';

@Injectable()
export class SetAuthenticationService
  implements SetAuthenticationServiceInterface
{
  private readonly _authenticationRepository =
    inject<AuthenticationRepositoryInterface>(Types.AuthenticationRepository);

  handle(input: AuthenticationTokenInput): Authentication {
    return this._authenticationRepository.setToken(input);
  }
}
