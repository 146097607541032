import { inject } from '@angular/core';
import { Types } from '../../types.domain';
import { Output } from '../../base/outputs';
import { User } from '../../user/entities/user';
import { SignatureType } from '../enums/signature-type';
import { Observable, forkJoin, from, switchMap } from 'rxjs';
import { QueueService } from '../../queues/services/queue.service';
import { QueueItemOutput } from '../../queues/dtos/queue-item.output';
import { CertificatesInterface } from '../interfaces/certificates.interface';
import { DocumentStorageSignInput } from '../dtos/document-storage.sign.input';
import { DocumentStorageSignQueueInput } from '../dtos/document-storage.sign-queue.input';
import { CreateSignatureServiceInterface } from '../interfaces/create-signature.service.interface';
import { SignatureRepositoryInterface } from '../interfaces/repositories/signature.repository.interface';
import { GetCurrentUserServiceInterface } from '../../user/interfaces/services/get-current-user.service.interface';
import { DocumentStorageSignQueueServiceInterface } from '../interfaces/document-storage.sign-queue.service.interface';

export class DocumentStorageSignQueueService implements DocumentStorageSignQueueServiceInterface {
  private readonly _signer = inject(Types.Signer);
  private readonly certificates = inject<CertificatesInterface>(Types.Certificates);
  private readonly _signatureRepository = inject<SignatureRepositoryInterface>(Types.SignatureRepository);
  private readonly _createSignatureService = inject<CreateSignatureServiceInterface>(Types.CreateSignature);
  private readonly _getCurrentUserService = inject<GetCurrentUserServiceInterface>(Types.GetCurrentUserService);

  handle(input: DocumentStorageSignQueueInput): Observable<QueueItemOutput<Output>> {
    if (input.signatureType === SignatureType.ICP) {
      return this.handleICP(input);
    } else {
      return this.handleInternal(input);
    }
  }

  private handleICP(input: DocumentStorageSignQueueInput): Observable<QueueItemOutput<Output>> {
    return forkJoin([
      from(this._getCurrentUserService.handle()),
      from(this.certificates.getCertificateBase64(input.certificateThumbprint)),
      from(this._signatureRepository.checkMyExistingSignaturesOnDocuments(input.documentCodes))
    ])
      .pipe(
        switchMap(([currentUser, certificateBase64, existingSignatures]) => {
          const queueItems = this.createQueueItems(input, currentUser, existingSignatures, certificateBase64);
          return this.buildQueue(queueItems, currentUser, SignatureType.ICP);
        })
      );
  }

  private handleInternal(input: DocumentStorageSignQueueInput): Observable<QueueItemOutput<Output>> {
    return forkJoin([
      from(this._getCurrentUserService.handle()),
      from(this._signatureRepository.checkMyExistingSignaturesOnDocuments(input.documentCodes))
    ])
      .pipe(
        switchMap(([currentUser, existingSignatures]) => {
          const queueItems = this.createQueueItems(input, currentUser, existingSignatures);
          return this.buildQueue(queueItems, currentUser, SignatureType.Internal);
        })
      );
  }

  private createQueueItems(
    input: DocumentStorageSignQueueInput,
    currentUser: User,
    existingSignatures: any[],
    certificateBase64?: string
  ): DocumentStorageSignInput[] {
    return input.documentCodes.map(documentCode => ({
      documentCode,
      certificateBase64,
      stampAdditionalInfos: input.stampAdditionalInfos,
      certificateThumbprint: input.certificateThumbprint,
      alreadySignedByCurrentUser: existingSignatures.some(signature =>
        signature.exists && signature.documentCode === documentCode
      ),
      certificate: input.certificate
    }));
  }

  private buildQueue(
    input: DocumentStorageSignInput[],
    currentUser: User,
    signatureType: SignatureType
  ): Observable<QueueItemOutput<Output>> {
    return new QueueService<Output>().handle(
      input.map(inputItem => ({
        identifier: inputItem.documentCode,
        handleExecution: () => this.signDocument(inputItem, currentUser, signatureType)
      }))
    );
  }

  private async signDocument(
    inputItem: DocumentStorageSignInput,
    currentUser: User,
    signatureType: SignatureType
  ): Promise<Output> {
    if (inputItem.alreadySignedByCurrentUser) {
      return Promise.resolve(new Output(['Document already signed']));
    }

    let signResult: Output;

    if (signatureType === SignatureType.ICP) {
      signResult = await this._signer.signUsingDocumentStorage(inputItem);
      if (signResult.hasErrors) {
        return signResult;
      }

    } else if (signatureType === SignatureType.Internal) {
      signResult = await this._signer.signInternalUsingDocumentStorage({
        documentCode: inputItem.documentCode,
        certificateBase64: inputItem.certificate?.contentBase64!,
        certificateKeyId: inputItem.certificate?.keyId!,
        stampAdditionalInfos: inputItem.stampAdditionalInfos,
      });

      if (signResult.hasErrors) {
        return signResult;
      }
    }

    try {
      await this._createSignatureService.handle({
        userId: currentUser.sub,
        userName: currentUser.name,
        accountPosition: inputItem.stampAdditionalInfos?.['Cargo'],
        signatureType,
        documentCode: inputItem.documentCode,
      });
    } catch (error) {
      return new Output(['Erro ao registrar assinatura.']);
    }

    return new Output([]);
  }
}
