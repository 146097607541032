import { Injectable, inject } from '@angular/core';

import { Authentication } from '../entities/authentication';
import { AuthenticationRepositoryInterface } from '../interfaces/repositories/authentication.repository.interface';

import { Types } from '../../types.domain';
import { GetAuthenticationServiceInterface } from '../interfaces/services/get-authentication.service.interface';

@Injectable()
export class GetAuthenticationService
  implements GetAuthenticationServiceInterface
{
  private readonly _authenticationRepository =
    inject<AuthenticationRepositoryInterface>(Types.AuthenticationRepository);

  handle(): Authentication {
    return this._authenticationRepository.getToken();
  }
}
