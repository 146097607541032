import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { InternalCertificateHttpRepository } from '../../../infrastructure/repositories/http/internal-certificate.http.repository';

export class InternalCertificateRepositoryInstruction implements ClassProvider {
  provide = Types.InternalCertificateRepository;
  deps = [Types.Settings];
  useClass = InternalCertificateHttpRepository;
}
