import { LacunaWebPKI, PadesSize, PadesVisualRectangle, VisualRepresentation } from "web-pki";

export class SignatureVisualRepresentation {
  
  get useLandscapeConfig(): boolean {
    return this.documentRotation === 90 || this.documentRotation === 270;
  }

  get containerConfig(): PadesVisualRectangle {
    return this.useLandscapeConfig 
      ? { left: 0.2, right: 0.2, bottom: 0.2, height: 0.5 }
      : { left: 0.2, right: 0.2, bottom: 0.2, height: 1.0 };
  }

  get signatureRectangleSizeConfig(): PadesSize {
    return this.useLandscapeConfig 
      ? { height: 0.5, width: 2.50 }
      : { height: 1.0, width: 3.50 };
  }

  constructor(
    private readonly documentRotation: number,
    private readonly stampAdditionalInfos?: { [key: string]: string }
  ) { }

  getDefault(): VisualRepresentation {
    const stampAdditionalInfosText = this.getStampAdditionalInfosText();
    return {
      text: {
        fontSize: 13.0,
        includeSigningTime: true,
        text: `Assinado digitalmente por {{signerName}}:{{signerNationalId}} ${stampAdditionalInfosText}`,
      },
      image: {
        resource: {
          url: "/assets/signatures/icp-stamp.png"
        }
      },
      position: {
        pageNumber: -1,
        auto: {
          container: this.containerConfig,
          signatureRectangleSize: this.signatureRectangleSizeConfig,
          rowSpacing: 0.0
        },
        measurementUnits: LacunaWebPKI.PadesMeasurementUnits.Centimeters
      }
    }
  }

  private getStampAdditionalInfosText(): string {
    if (!this.stampAdditionalInfos) {
      return '';
    }

    return Object
      .keys(this.stampAdditionalInfos)
      .map(key => `\n${key}: ${this.stampAdditionalInfos![key]}`)
      .join();
  }
  
}