import { inject } from '@angular/core';

import { Types } from '../../types.domain';
import { Document } from '../../document/entities/document';
import { SignatureUtils } from '../../utils/signature.utils';
import { SignStreamStrategy } from '../enums/sign-stream-strategy';
import { BrowserSignStreamService } from '../services/browser.sign-stream.service';
import { SignStreamServiceInterface } from '../interfaces/sign-stream.service.interface';
import { SignStreamServiceFactoryInterface } from '../interfaces/factories/sign-stream.service.factory.interface';
import { RemoteServerSignStreamService } from '../services/remote-server.sign-stream.service';

export class SignStreamServiceFactory implements SignStreamServiceFactoryInterface {
  private readonly _signer = inject(Types.Signer);
  private readonly _certificates = inject(Types.Certificates);
  private readonly _signatureRepository = inject(Types.SignatureRepository);
  
  create(document: Document): SignStreamServiceInterface {
    const strategy = SignatureUtils.availableSignStreamStrategy(document.size);
    switch (strategy) {
      case SignStreamStrategy.OnBrowser:
        return new BrowserSignStreamService(document, this._signer, this._signatureRepository);
      default:
      case SignStreamStrategy.OnRemoteServer:
        return new RemoteServerSignStreamService(document, this._signer, this._certificates, this._signatureRepository);
    }
  } 
}
