import { Output } from "../../base/outputs";

export class UploadDocumentOutput extends Output {

  updatedRemoteDocumentUrl?: string;

  constructor(data: Partial<UploadDocumentOutput>) {
    super();
    Object.assign(this, data);
  }
}
