import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { SignAndUploadDocumentService } from '../../../domain/signatures/services/sign-and-upload-document.service';

export class SignAndUploadDocumentServiceInstruction implements ClassProvider {
  provide = Types.SignAndUploadDocumentService;
  deps = [Types.UploadDocumentService];
  useClass = SignAndUploadDocumentService;
}
