import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Types } from '../../domain/types.domain';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const getAuthService = inject(Types.GetAuthenticationService); //
  if (req.headers.get(`excludeInterceptors`) === `true`) {
    let headers = req.clone({
      headers: req.headers.delete('excludeInterceptors') as HttpHeaders,
    });
    return next(headers);
  }

  const token = getAuthService.handle()?.token;
  const tokenReq = req.clone({
    setHeaders: { Authorization: `Bearer ${token}` },
  });

  return next(tokenReq);
};
