import { Injectable, inject } from '@angular/core';
import { Types } from '../../types.domain';

import {  InternalCertificateRepositoryInterface } from '../interfaces/repositories/internal-certificate.repository.interface';
import { InternalCertificate } from '../dtos/create-internal-certificate.output';
import { CreateInternalCertificateServiceInterface } from '../interfaces/create-internal-certificate.service.interface';

@Injectable()
export class CreateCertificateService implements CreateInternalCertificateServiceInterface {
  private readonly _internalCertificateRepository = inject<InternalCertificateRepositoryInterface>(Types.InternalCertificateRepository);

  async handle(): Promise<InternalCertificate> {
    return this._internalCertificateRepository.create();
  }
}
