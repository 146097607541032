import { HttpErrorResponse } from '@angular/common/http';
import { ErrorEnum } from './error.enum';
import { Output } from './outputs';

export class ErrorTranslator {
  static translateFromError(error: any): Output {
    let errorMessages: string[] | null = [];
    if (error instanceof HttpErrorResponse) {
      errorMessages = ErrorTranslator.getHttpHandledErrors(error);
    }

    if (error === 'Document already signed') {
      errorMessages?.push(error);
    }

    const resultErrors = errorMessages?.length ? errorMessages : [ErrorEnum.GENERIC];
    return new Output(resultErrors);
  }

  private static getHttpHandledErrors(httpError: HttpErrorResponse): string[] | null {
    const errors: string[] = httpError.error?.handledErrors;

    const notEmptyErrors = errors?.filter((error: string) => !!error.trim());
    return notEmptyErrors;
  }
}
