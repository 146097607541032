import { Document } from '../../document/entities/document';
import { SignStreamInput } from '../dtos/sign-stream.input';
import { SignStreamOutput } from '../dtos/sign-stream.output';
import { ErrorTranslator } from '../../base/error-translator';
import { SignerInterface } from '../interfaces/signer.interface';
import { SignStreamStrategy } from '../enums/sign-stream-strategy';
import { FileConvertionsUtils } from '../../utils/file-convertions.utils';
import { CertificatesInterface } from '../interfaces/certificates.interface';
import { SignStreamServiceInterface } from '../interfaces/sign-stream.service.interface';
import { SignatureRepositoryInterface } from '../interfaces/repositories/signature.repository.interface';

export class RemoteServerSignStreamService implements SignStreamServiceInterface {
  readonly strategy = SignStreamStrategy.OnRemoteServer;

  constructor(
    public readonly document: Document,
    private readonly _signer: SignerInterface, 
    private readonly _certificates: CertificatesInterface,
    private readonly _signatureRepository: SignatureRepositoryInterface,
  ) {}

  async handle(input: SignStreamInput): Promise<SignStreamOutput> {
    const documentCode = input.document.code;
    const alreadySigned = await this._signatureRepository.userAlreadySigned(documentCode);
    if (alreadySigned) {
      const errorOutput =  ErrorTranslator.translateFromError('Document already signed');
      return new SignStreamOutput({ ...errorOutput, documentCode });
    }

    try {
      const documentBuffer = input.document.buffer;
      const documentFilename = input.document.filename!;
      const documentFile = FileConvertionsUtils.arrayBufferToFile(documentFilename, documentBuffer!);
      const certificateBase64 = await this._certificates.getCertificateBase64(input.certificateThumbprint);
      const signOnBrowserOutput = await this._signer.signStreamOnRemoteServer({
        certificateBase64,
        document: documentFile,
        stampAdditionalInfos: input.stampAdditionalInfos,
        certificateThumbprint: input.certificateThumbprint,
      });

      const signedDocumentUrl = signOnBrowserOutput.signedDocumentUrl;
      return new SignStreamOutput({ signedDocumentUrl, documentCode });
    } 
    catch (error) {
      const errorOutput =  ErrorTranslator.translateFromError(error);
      return new SignStreamOutput({ ...errorOutput, documentCode });
    }
  }
}
