import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { UploadDocumentService } from '../../../domain/document/services/upload-document.service';

export class UploadDocumentServiceInstruction implements ClassProvider {
  provide = Types.UploadDocumentService;
  deps = [Types.Settings, Types.DocumentRepository, Types.CreateSignature, Types.GetCurrentUserService];
  useClass = UploadDocumentService;
}
