import { NavigatorUtils } from "./navigator.utils";
import { MeasureConvertionsUtils } from "./measure-convertions.utils";
import { SignStreamStrategy } from "../signatures/enums/sign-stream-strategy";

export class SignatureUtils {
  
  static readonly signOnBrowserBytesLimit = MeasureConvertionsUtils.megabytesToBytes(10);

  static sizeIsSupportedInBrowserSign(documentSize: number): boolean {
    return documentSize < SignatureUtils.signOnBrowserBytesLimit;
  }

  static availableSignStreamStrategy(documentSize: number): SignStreamStrategy {
    const sizeIsSupportedInBrowserSign = SignatureUtils.sizeIsSupportedInBrowserSign(documentSize);
    return sizeIsSupportedInBrowserSign && !NavigatorUtils.isMobileEnvironment()
        ? SignStreamStrategy.OnBrowser
        : SignStreamStrategy.OnRemoteServer;
  }
}